<template>
    <div id="profile">
        <v-card  class="medium-card">
            <v-card-title class="profile-title">
              <v-icon v-if="prizeable" style="padding-right:8px" title="Team is eligible to win prizes">mdi-trophy</v-icon>
              {{ username || "Something went wrong"}}
              <span class="group-indicator" v-if="$store.state.group != 'regular'">({{$store.state.group}})</span>
              <v-spacer></v-spacer>
              {{ (extrapoints > 0 ? sseDynamicScore + extrapoints : sseDynamicScore) || score }}
            </v-card-title>
            <v-card-text v-if="association">{{ association }}</v-card-text>
            <h3 class="slim-title">Solved Challenges:</h3>
            <div v-if="$store.state.dynamic.solves == null || !$store.state.dynamic.challenges">
              <v-card-text v-if="$store.state.dynamic.challenges" class="card-subtitle-center">No solved challenges</v-card-text>
              <v-card-text v-else class="card-subtitle-center">No challenges available</v-card-text>
            </div>
            <div v-else class="minimal-card-wrap" v-for="solveID in $store.state.dynamic.solves" v-bind:key="solveID">
              <v-card class="solved-challenge-card" color="primary">
                <v-card-title>
                        {{ $store.state.dynamic.challenges[solveID].name }}
                        <v-spacer></v-spacer>
                        {{ $store.state.dynamic.challenges[solveID].points }}
                      </v-card-title>
              </v-card>
            </div>
        </v-card>
        <v-card class="medium-card">
          <v-card-title>Settings</v-card-title>
          <v-subheader>Dark Theme
            <v-spacer></v-spacer>
            <v-switch v-model="options.darkTheme" @change="updateTheme()"></v-switch>
          </v-subheader>
          <v-subheader>Use notification sound
            <v-spacer></v-spacer>
            <v-switch v-model="options.sound" @change="updateSound()"></v-switch>
          </v-subheader>
          <br>
          <v-divider class="mx-4"></v-divider>
          <br>
          <v-card-title>Change Password</v-card-title>
          <v-card-text>
            <v-form ref="changePasswordForm">
                <v-text-field type="password" label="Old Password" :rules="password.pwRule" ref="old" @focus="$refs.old.resetValidation()" v-model="password.old"></v-text-field>
                <v-text-field type="password" label="New Password" :rules="password.pwRule" ref="new" @focus="$refs.new.resetValidation()" v-model="password.new"></v-text-field>
                <v-text-field type="password" label="Confirm New Password" :rules="password.pwRule" ref="confirm" @focus="$refs.confirm.resetValidation()" v-model="password.confirm"></v-text-field>
                <v-btn class="buttonPw" block large color="primary" @click="changePassword()" :loading="password.loading">Change Password</v-btn>
            </v-form>
            <div v-if="password.error" class="error-alert-wrap">
                <v-alert :type="password.errorType">{{password.error}}</v-alert>
            </div>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
          <br>
          <v-card-text>
            <v-btn @click="logout()" color="error" block outlined depressed class="logout-button">Logout</v-btn>
          </v-card-text>
        <br>
        </v-card>
    </div>
</template>
<script>
import api from '@/Requests.js'
export default {
    data: () => ({
        username: undefined,
        association: undefined,
        prizeable: false,
        score: 0,
        extrapoints: 0,
        password: {
            pwRule: [v => !!v || "This field is required"],
            old: "",
            new: "",
            confirm: "",
            error: false,
            errorType: "error",
            loading: false
        },
        options: {
          darkTheme: null,
          sound: null
        }
    }),
    methods: {
        changePassword() {
            this.password.error = false
            if (!this.$refs.changePasswordForm.validate()) {
              return false
            }
            if (this.password.new != this.password.confirm) {
                this.password.errorType = "warning"
                this.password.error = "New passwords must match"
                return false
            }
            this.password.loading = true
            api.post("/ctf/changepassword", {"oldpassword": this.password.old, "newpassword": this.password.new}, this.$store.state.token)
            .then(res => {
                this.password.loading = false
                this.password.error = res.message
                if (!res.success) {
                    this.password.errorType = "error"
                    return false
                }
                this.password.errorType = "success"
            });
        },
        logout() {
            this.$store.commit('logout')
            this.$router.push('/')
        },
        updateTheme() {
          this.$store.commit('setDarkTheme', this.options.darkTheme);
          this.$vuetify.theme.dark = this.options.darkTheme;
        },
        updateSound() {
          this.$store.commit('setNotifySound', this.options.darkTheme);
        }
    },
    computed: {
        sseDynamicScore: function() {
            if (this.$store.state.dynamic.solves == null) {
                return 0
            }
            if (!this.$store.state.dynamic.challenges) {
              return false
            }
            let score = 0
            this.$store.state.dynamic.solves.forEach(solvedChallengeId => {
              score += this.$store.state.dynamic.challenges[solvedChallengeId].points
            });
            return score
        }
    },
    mounted() {
        api.get('/ctf/profile', this.$store.state.token)
        .then(res => {
            this.username = res.username
            this.association = res.association
            this.score = res.score
            this.extrapoints = res.extrapoints
            this.prizeable = res.prize_eligible
        });
        this.options.sound = this.$store.state.options.useNotifySound;
        this.options.darkTheme = this.$store.state.options.useDarkTheme;
    },
};
</script>

<style scoped>
.card-title-center {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 0.2rem;
}

.theme--light .solved-challenge-card {
  color: #fff;
}

.profile-title {
  font-size: 1.5rem;
  padding-bottom: 0;
}

.group-indicator {
    padding-left: 0.5rem;
}

.card-subtitle-center {
  display: flex;
  justify-content: center;
}

.minimal-card-wrap {
  padding: 0.5rem 1rem;
}

.minimal-card-wrap:last-child {
  padding-bottom: 1rem;
}

.slim-title {
  text-align: center;
  font-weight: 300;
  padding-bottom: 0.5rem;
}

.buttonPw {
    margin-top: 0.5rem;
}

.error-alert-wrap {
    padding: 1rem 0 0 0;
}
</style>
