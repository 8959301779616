import Vue from 'vue'
import store from '@/store'
import VueRouter from 'vue-router'
import Home from '@/views/Home'
import Rules from '@/views/Rules'
import Scoreboard from '@/views/Scoreboard'
import Challenges from '@/views/Challenges'
import Profile from '@/views/Profile'
import Login from '@/views/Login'
import Register from '@/views/Register'
import Notifications from '@/views/Notifications'
import Announcer from '@/views/Announcer'

import Admin from '@/views/Admin'
import AdminHome from '@/components/admin/Home'
import AdminSettings from '@/components/admin/Settings'
import AdminNotify from '@/components/admin/Notifications'
import AdminScoreboard from '@/components/admin/Scoreboard'
import AdminAccounts from '@/components/admin/Accounts'
import AdminChallenges from '@/components/admin/Challenges'
import AdminSchedule from '@/components/admin/Schedule'


Vue.use(VueRouter)
Vue.use(store)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/rules',
    name: 'Rules',
    component: Rules
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/tv',
    name: 'annnouncer',
    component: Announcer,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/scoreboard',
    name: 'Scoreboard',
    component: Scoreboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/challenges',
    name: 'Challenges',
    component: Challenges,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications,
    meta: {
      requiresAuth: true
    }
  },
  {
      path: '/admin',
      component: Admin,
      meta: {
          restrictedToAdminOnly: true
      },
      children: [
        {
          path: '',
          name: 'Administration',
          component: AdminHome
        },
        {
          path: 'challenges',
          name: 'Admin: Challenges',
          component: AdminChallenges
        },
        {
          path: 'schedule',
          name: 'Admin: Schedule',
          component: AdminSchedule
        },
        {
          path: 'notifications',
          name: 'Admin: Notifications',
          component: AdminNotify
        },
        {
          path: 'settings',
          name: 'Admin: Settings',
          component: AdminSettings
        },
        {
          path: 'scoreboard',
          name: 'Admin: Scoreboard',
          component: AdminScoreboard
        },
        {
          path: 'accounts',
          name: 'Admin: Accounts',
          component: AdminAccounts
        }
      ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.name + (store.state.ctf_info.title ? " | " + store.state.ctf_info.title : "")
  if(to.matched.some(record => record.meta.requiresAuth) && store.state.token == undefined) {
    next('/login')
  } else if(to.matched.some(record => record.meta.restrictedToAdminOnly) && store.state.group != "admin") {
    next('/')
  } else {
    next()
  }

})

export default router
