<template>
  <div class="admin-challenges">
    <v-card class="major-card">
      <v-card-title>
        Challenges
        <v-spacer></v-spacer>
        <v-btn min-width="102px" :disabled="stickyAlert.display || refreshBtnPressed" color="primary" @click="refresh()">
          <span v-if="!refreshBtnPressed">Refresh</span>
          <v-icon v-else>mdi-check</v-icon>
        </v-btn>
      </v-card-title>
      <div v-if="challenges">
        <v-data-table
          :headers="headers"
          :items="challenges"
          :items-per-page="-1"
          hide-default-footer
          sort-by="id"
          item-key="id"
          class="elevation-1"
        >
        <!-- eslint-disable-next-line vue/valid-v-slot -->
        <template v-slot:item.enabled="challenge">
          <v-checkbox @change="diff" v-model="challenge.item.enabled" ></v-checkbox>
        </template>
        <!-- eslint-disable-next-line vue/valid-v-slot -->
        <template v-slot:item.id="challenge">
          <v-btn text @click="editChallenge(challenge.item.id)">
             <v-icon>mdi-tooltip-edit-outline</v-icon>
          </v-btn>
        </template>
        </v-data-table>
      </div>
    </v-card>
    <div class="challenges-alert-wrap" v-if="stickyAlert.display">
      <v-alert :type="stickyAlert.type">
        <v-row class="alert-interactive-content">
          <span class="challenges-alert-text">{{stickyAlert.text}}</span>
          <v-spacer></v-spacer>
          <span v-if="stickyAlert.type == 'warning'">
            <v-btn text depressed @click="refresh()">Discard</v-btn>&nbsp;
            <v-btn color="orange darken-4" depressed @click="updateChallenges()">Save</v-btn>
          </span>
          <span v-else>
            <v-btn text @click="stickyAlert.display = false">Dismiss</v-btn>
          </span>
        </v-row>
        </v-alert>
    </div>
    <v-dialog v-model="editDialog" persistent width="700" internal-activator>
      <v-card>
        <v-card-title>Edit Challenge</v-card-title>
        <v-card-text>
          <v-text-field label="Name" v-model="dialogData.name"></v-text-field>
          <v-text-field label="Author" v-model="dialogData.author"></v-text-field>
          <v-select :items="categories" label="Category" v-model="dialogData.category"></v-select>
          <v-text-field v-if="dialogData.category == newCategoryPlaceholder" label="New Category" v-model="dialogData.newCategory">
          </v-text-field>
          <br>
          <v-textarea label="Description" outlined v-model="dialogData.description"></v-textarea>
          <v-text-field label="Flag" v-model="dialogData.flag"></v-text-field>
          <div v-if="dialogError">
            <v-alert :type="dialogErrorType">{{ dialogError }}</v-alert>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn large text @click="dialogData = {}; editDialog = false">Cancel</v-btn>
          <v-btn color="primary" large @click="sumbitEditChallenge()" :loading="isLoading">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from "@/Requests.js";
export default {
  name: "admin-challenges",
  data: () => ({
    originalState: {},
    challenges: [],
    categories: [],
    headers: [
      {
        text: 'Enabled',
        value: 'enabled'
      },
      {
        text: 'Challenge name',
        value: 'name'
      },
      {
        text: 'Category',
        value: 'category'
      },
      {
        text: 'Author',
        value: 'author'
      },
      {
        text: 'Edit',
        align: 'right',
        value: 'id'
      }
    ],
    stickyAlert: {
        display: false,
        color: undefined,
        text: undefined,
        type: undefined
      },
    error: false,
    refreshBtnPressed: false,
    editDialog: false,
    dialogData: {},
    orgDialogData: {},
    dialogError: false,
    dialogErrorType: undefined,
    isLoading: false,
    newCategoryPlaceholder: "New Category..."
  }),
  methods: {
    getChallenges: function() {
      api.get("/admin/challenges", this.$store.state.token)
      .then(res => {
        if (!res.success) {
          this.error = res.message
          return false
        }
        this.challenges = []
        this.originalState = {}
        this.challenges = res.challenges
        for (let i = 0; i < res.challenges.length; i++) {
          this.originalState[res.challenges[i].id] = res.challenges[i].enabled
        }
        this.diff()
      })
    },
    updateChallenges: function() {
      let challengesToUpdate = {}
      this.challenges.forEach(chall => {
        if (chall.enabled != this.originalState[chall.id]) {
          challengesToUpdate[chall.id] = {enabled: chall.enabled}
        }
      })
      api.post("/admin/challenges", challengesToUpdate, this.$store.state.token)
      .then(res => {
        if (!res.success) {
          this.error = res.message
          return false
        }
        this.getChallenges()
      })
    },
    refresh: function() {
      this.refreshBtnPressed = true
      setTimeout(() => {
          this.refreshBtnPressed = false
      }, 2000);
      this.getChallenges()
    },
    diff: function() {
      let hasUnsavedChanges = false
      this.challenges.forEach(chall => {
        if (chall.enabled != this.originalState[chall.id]) {
          hasUnsavedChanges = true
        }
      });
      if (hasUnsavedChanges) {
        this.displayUnsavedChanges()
      } else {
        this.noSavedChanges()
      }
    },
    displayUnsavedChanges: function() {
      this.stickyAlert.display = true
      this.stickyAlert.type = "warning"
      this.stickyAlert.color = "orange darken-4"
      this.stickyAlert.text = "You have unsaved changes"
    }, 
    noSavedChanges: function() {
      this.stickyAlert.display = false
    },
    editChallenge: function(id) {
      api.get("/admin/categories", this.$store.state.token)
      .then(res => {
        if (!res.success) {
          this.error = res.message
          return false
        }
        this.error = false
        this.categories = res.categories
        this.categories.push(this.newCategoryPlaceholder)
      })
      let challenegeData = this.challenges.find(x => {return x.id == id})
      //Object.assign(this.dialogData, challenegeData)
      this.dialogData = challenegeData
      Object.assign(this.orgDialogData, challenegeData)
      this.editDialog = true
    },
    sumbitEditChallenge: function() {
      let modifiedData = {}
      modifiedData[this.dialogData.id] = {}
      Object.keys(this.orgDialogData).forEach(key => {
        // Check all regular keys
        if (this.orgDialogData[key] != this.dialogData[key]) {
          if (key == "category" && this.dialogData[key] == this.newCategoryPlaceholder) {
            modifiedData[this.dialogData.id][key] = this.dialogData["newCategory"]
          } else {
            modifiedData[this.dialogData.id][key] = this.dialogData[key]
          }
        }
      })
      if (this.dialogData.flag != undefined) {
        if (this.dialogData.flag != "") {
          modifiedData[this.dialogData.id].flag = this.dialogData.flag
        }
      }
      if (Object.keys(modifiedData[this.dialogData.id]).length == 0) {
        this.dialogError = "No changes made, unable to save."
        this.dialogErrorType = "warning"
        return false
      }
      api.post("/admin/challenges", modifiedData, this.$store.state.token)
      .then(res => {
        if (res.success) {
            this.editDialog = false
            this.dialogData = {}
            this.refresh()
            return true
        }
        this.dialogError = res.error ? res.message + ". Reason: " + res.error : res.message
        this.dialogErrorType = "error"
      })

    }
  },
  created() {
    this.getChallenges()
  }
};
</script>

<style scoped>
.card-wrap {
  display: flex;
  flex-wrap: wrap;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.inner-card-title {
  padding-left: 0;
}

.challenges-alert-wrap {
  position: fixed;
  bottom: 2rem;
  left: 2rem;
  right: 2rem;
}

.alert-interactive-content {
  padding: 8px;
}

.challenges-alert-text {
  display: flex;
  align-items: center;
}
</style>