<template>
    <div class="scoreboard">
        <v-card class="major-card">
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th>Place</th>
                            <th>Team</th>
                            <th>Score</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="team in tableData" v-bind:key="team.name">
                            <td>
                                {{ team.place }}
                                <v-icon style="padding-left:8px" v-if="team.prize_eligible" size="1.2rem" title="Team is eligible to win prizes">mdi-trophy</v-icon>
                            </td>
                            <td>{{ team.username }}</td>
                            <td>{{ team.score }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card>
        <div style="display:flex; align-items:center; justify-content:center;">
            <v-switch v-model="showOnlyPrizable" @change="updateScoreboardDisplaySetting()" label="Show only Prize Eligible"></v-switch>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        showOnlyPrizable: false
    }),
    methods: {
        updateScoreboardDisplaySetting() {
            this.$store.commit('setScoreboardOnlyPrizeEligible', this.showOnlyPrizable);
        }
    },
    computed: {
        tableData: function() {
            if (!this.$store.state.dynamic.scoreboard) return
            if (this.showOnlyPrizable) return this.$store.state.dynamic.scoreboard.filter(team => team.prize_eligible ? true : false)
            return this.$store.state.dynamic.scoreboard
        }
    },
    mounted() {
         this.showOnlyPrizable = this.$store.state.options.ScoreboardOnlyPrizeEligible
    }
}
</script>