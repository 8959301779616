<template>
    <div class="component">
        <v-toolbar class="navbar">
            <v-toolbar-title class="logo">{{ $store.state.ctf_info.title || "CTF TITLE" }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <span>{{ timeInfo }}</span>
            <v-spacer></v-spacer>
            <span v-if="online" title="You're online, no need to refresh">
                <v-icon size="1.2rem">mdi-cloud-outline</v-icon>
            </span>
            <span v-else-if="$store.state.token" title="System offline please reload">
                <v-icon id="redcloud" size="1.2rem">mdi-cloud-off-outline</v-icon>
            </span>
            <div class="links">
                <router-link class="nav-link" to="/">Home</router-link>
                <router-link class="nav-link" to="/rules">Rules</router-link>
                <span v-if="$store.state.token">
                    <router-link class="nav-link" to="/challenges">Challenges</router-link>
                    <router-link class="nav-link" to="/scoreboard">Scoreboard</router-link>
                    <router-link class="nav-link" to="/profile">Profile</router-link>
                    <router-link v-if="$store.state.group == 'admin'" class="nav-link" to="/admin">Administration</router-link>
                </span>
                <span v-else>
                    <router-link class="nav-link" to="/login">Login</router-link>
                    <span v-if="$store.state.ctf_info.signup">
                        <router-link class="nav-link" to="/register">Register</router-link>
                    </span>
                </span>
            </div>
            <span v-if="$store.state.token">
                <router-link class="notify-link font-weight-medium" :class="notifyStatus" to="/notifications">
                    <v-icon class="notify-bell-icon" v-if="notifications" small>mdi-bell-alert</v-icon>
                    <v-icon class="notify-bell-icon" v-else small>mdi-bell</v-icon>
                    {{notifications}}
                </router-link>
            </span>
            <div class="burgsmenu">
                <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            </div>
        </v-toolbar>
        <v-navigation-drawer v-model="drawer" temporary absolute right>
            <v-list dense>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>{{ $store.state.ctf_info.title || "CTF TITLE" }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <router-link class="nav-link" to="/">Home</router-link>
                </v-list-item>
                 <v-list-item>
                        <router-link class="nav-link" to="/rules">Rules</router-link>
                </v-list-item>
                <span v-if="$store.state.token">
                    <v-list-item>
                        <router-link class="nav-link" to="/challenges">Challenges</router-link>
                    </v-list-item>
                    <v-list-item>
                        <router-link class="nav-link" to="/scoreboard">Scoreboard</router-link>
                    </v-list-item>
                    <v-list-item>
                        <router-link class="nav-link" to="/profile">Profile</router-link>
                    </v-list-item>
                    <span v-if="$store.state.group == 'admin'">
                        <v-list-item>
                            <router-link v-if="$store.state.group == 'admin'" class="nav-link" to="/admin">Administration</router-link>
                    </v-list-item>
                    </span>
                </span>
                <span v-else>
                    <v-list-item>

                            <router-link class="nav-link" to="/login">Login</router-link>

                    </v-list-item>
                    <span v-if="$store.state.ctf_info.signup">
                        <v-list-item>

                            <router-link class="nav-link" to="/register">Register</router-link>

                    </v-list-item>
                    </span>
                </span>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
// Notification sound from https://github.com/akx/Notifications licenced under CC0 Public Domain
const sound = new Audio(require('@/assets/notify.ogg'))
export default {
    data: () => ({
      currentTime: new Date().getTime(),
      timeInfo: undefined,
      notifyStatus: "no-unreads",
      online: false,
      drawer: null
    }),
    computed: {
        timeCalc: function() {
          let prefix = ""
          let target = undefined
          let res = undefined

          if (this.$store.state.dynamic.state.manual) {
              if (this.$store.state.dynamic.state.active) return;
              return "CTF is not active";
          }

          if (new Date(this.$store.state.dynamic.state.start_time).getTime() >= this.currentTime) {
            target = new Date(this.$store.state.dynamic.state.start_time).getTime()
            res = target - this.currentTime
            prefix = "Starts In "
          } else if (new Date(this.$store.state.dynamic.state.end_time).getTime() >= this.currentTime) {
              target = new Date(this.$store.state.dynamic.state.end_time).getTime()
              res = target - this.currentTime
              prefix = "Ends In "
          } else {
            return "CTF is not active"
          }

          let days = Math.floor(res / (1000 * 60 * 60 * 24));
          let hours = Math.floor((res % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          let minutes = Math.floor((res % (1000 * 60 * 60)) / (1000 * 60));
          let seconds = Math.floor((res % (1000 * 60)) / 1000);

          let timerString = prefix

          if (days >= 1) {
              timerString += days
              if (days == 1) {
                  timerString += " day "
              } else {
                  timerString += " days "
              }
              timerString += "and "
          }
          //
          timerString += (hours.toString().length == 1 ? "0" + hours : hours) + ":"
          timerString += (minutes.toString().length == 1 ? "0" + minutes : minutes) + ":"
          timerString += seconds.toString().length == 1 ? "0" + seconds : seconds
          return timerString
        },
        notifications: function() {
            if (this.$store.state.dynamic.notifications.length < 1) {
                return 0
            }
            return this.$store.state.dynamic.notifications.filter(x => !this.$store.state.dynamic.readNotifications.includes(x.id)).length
        }
    },
    watch: {
        notifications: function (current, old) {
            if (current > old) {
                this.notifyStatus = "no-unreads"
                if (this.$store.state.options.useNotifySound) {
                    sound.play()
                }
                setTimeout(() => {
                    this.notifyStatus = "new-unreads"
                }, 150)
            } else if (this.notifications == 0) {
                this.notifyStatus = "no-unreads"
            }
        }
    },
    created() {
      setInterval(() => {
        this.currentTime = new Date().getTime()
        this.timeInfo = this.timeCalc
        if (this.$store.state.token) {
            if (this.$store.state.dynamic.eventSource.readyState == 1) {
                // We're online
                this.online = true
            } else {
                // Not online
                this.online = false
            }
        } else {
            this.online = false
        }
      }, 1000)
    },
}
</script>

<style scoped>

.nav-link {
    margin: 0 0.5rem;
    padding: 0.3rem 0;
    outline: 0;
    color: inherit;
    text-decoration: none;
    border: 1px solid #ffffff00;
    transition: 0.2s;
}

.nav-link:hover {
    transition: 0.2s;
    border-bottom: 1px solid;
}

.notify-link {
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
    color: inherit;
    text-decoration: none;
    padding: 8px 16px;
    border-radius: 20px;
    background: #424242; /* TODO: Replace with css variable */
}

.notify-bell-icon {
    color: #fff;
    padding-right: 5px;
}

.notify-link:hover {
    background: #616161;
}

.theme--light .notify-link {
    background: #dfdfdf;
}

.theme--light .notify-link,
.theme--light .notify-link .notify-bell-icon {
    color: #616161;
}

.theme--light .notify-link:hover {
    background: #cfcfcf;
}

.theme--light .new-unreads {
    animation: unread 1s linear;
    background: #ef5350;
}
.theme--light .new-unreads:hover {
    background: #ff5252;
}

.new-unreads {
    animation: unread 1s linear;
    background: #ef5350;
}
.new-unreads:hover {
    background: #ff5252;
}

.theme--light .new-unreads,
.theme--light .new-unreads .notify-bell-icon {
    color: #fff;
}

.cloud {
    margin-right: 0.5rem;
}

#redcloud {
    color: #ef5350;
}

.burgsmenu {
    display: none;
}

@keyframes unread {
    0% {
        background: rgba(0,0,0,0);
    }
    33% {
        background: #ef5350;
    }
    66% {
        background: rgba(0,0,0,0);
    }
    100% {
        background: #ef5350;
    }
}

@media screen and (max-width: 1024px) {
    .links {
        display: none;
    }
    .burgsmenu {
        display: block;
        padding-left: 0.5rem;
    }
}
</style>
