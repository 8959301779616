<template>
    <div class="announcer">
        <v-simple-table>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="tv">Place</th>
                        <th class="tv">Team</th>
                        <th class="tv">Score</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="team in $store.state.dynamic.scoreboard" v-bind:key="team.name">
                        <td class="tv">
                            {{ team.place }}
                            <v-icon style="padding-left:8px" v-if="team.prize_eligible" size="3.5rem">mdi-trophy</v-icon>
                        </td>
                        <td class="tv">{{ team.username }}</td>
                        <td class="tv">{{ team.score }}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-dialog v-model="dialog" internal-activator width="100%" class="fb-dialog">
            <v-card class="firstblood-card" :class="setBackgroundImage(category)">
                <div>{{account}} just solved</div>
                <div>{{challenge}}</div>
            </v-card>
      </v-dialog>
    </div>
</template>

<script>
const sound = new Audio(require('@/assets/firstblood.mp3'))
export default {
    data: () => ({
      dialog: false,
      challenge: undefined,
      category: undefined,
      account: undefined
    }),
    mounted() {
        // Hide the navbar
        let navbar = document.querySelector('.component');
        navbar.querySelector('.navbar span').style.fontSize = '1.5rem'
        navbar.querySelector('.logo').style.display = 'none';
        navbar.querySelector('.links').style.display = 'none';
        navbar.querySelector('.notify-link').style.display = 'none';
    },
    methods: {
        setBackgroundImage: function(category) {
            if (this.dialog === false) return '';
            //bg1 is reserved for crypto
            if (category === 'crypto') return 'bg1';
            return 'bg' + Math.floor(Math.random()*8 + 2);
        }
    },
    computed: {
        firstblood: function() {
            return this.$store.state.dynamic["first-blood"].length;
        }
    },
    watch: {
        firstblood: function (current) {
            if (current == 0) return;
            sound.play();
            this.$store.dispatch('fetchFirstBloodEvent').then(blood => {
                this.dialog = true;
                this.challenge = blood.challenge;
                this.category = blood.category;
                this.account = blood.account;
                setTimeout(() => {
                    this.dialog = false;
                }, 8 * 1000);
            })
        }
    },
}
</script>


<style>
.announcer {
    padding: 1rem;
}
.tv {
    font-size: 3.5rem !important;
    padding: .5rem 1rem !important;
}

.v-dialog {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
}

.firstblood-card {
    height: 90vh;
    background-color: rgba(0,0,0,0) !important;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 4.5rem;
    font-weight: bold;
    color: #fff !important;
    text-shadow: 0 0 6px #000;
}

.bg1 {
    background-image: url('~@/assets/firstblood_gifs/crypto.gif');
}
.bg2 {
    background-image: url('~@/assets/firstblood_gifs/delete-computer.gif');
}
.bg3 {
    background-image: url('~@/assets/firstblood_gifs/dualhacking.webp');
}
.bg4 {
    background-image: url('~@/assets/firstblood_gifs/hackercat.webp');
}
.bg5 {
    background-image: url('~@/assets/firstblood_gifs/hackers-in-mainframe.gif');
}
.bg6 {
    background-image: url('~@/assets/firstblood_gifs/hacking1.webp');
}
.bg7 {
    background-image: url('~@/assets/firstblood_gifs/hacking2.gif');
}
.bg8 {
    background-image: url('~@/assets/firstblood_gifs/programming.gif');
}
.bg9 {
    background-image: url('~@/assets/firstblood_gifs/sombra.gif');
}

</style>