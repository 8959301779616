<template>
  <div id="Notifications">
    <div class="center-wrap">
      <h1 class="display-1 d1-title">Notifications</h1>
    </div>
    <div class="medium-card" v-if="$store.state.dynamic.notifications.length > 0">
      <v-expansion-panels v-model="expanded" focusable accordion>
        <v-expansion-panel v-for="notification in notifications" v-bind:key="notification.id">
          <v-expansion-panel-header class="font-weight-medium" :class="isUnread(notification.id)" @click="read(notification.id)">{{ notification.title }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="date-display">{{ new Date(notification.time).toLocaleString() }}</div>
            <div v-for="(line, i) in notification.message.split('\n')" v-bind:key="i" class="notification-text-line">
              {{line}}
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <v-card v-else class="major-card">
      <v-card-text>You have 0 notifications</v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    expanded: undefined,
    headers:[
      {
        text: "Title",
        value: "title",
        sortable: false
      },
      {
        value: 'data-table-expand'
      }
    ]
  }),
  methods: {
    isUnread: function(id) {
      if (!this.$store.state.dynamic.readNotifications.includes(id)) return "unread"
      return "read"
    },
    read: function(id) {
        if (!this.$store.state.dynamic.readNotifications.includes(id)) {
            this.$store.commit('appendRead', id)
        }
    }
  },
  computed: {
    notifications: function () {
      return this.$store.state.dynamic.notifications
    }
  }
}
</script>

<style scoped>
.unread {
    background: #424242;
}

.theme--light .read {
    background: #efefef;
}

.theme--light .unread {
    background: #fff;
}

.v-expansion-panel-content__wrap { /* OVERRIDE for vuetifys uneven padding */
  padding: 16px 24px !important;
}

.date-display {
  padding: 0.5rem 0;
  color: #757575; /* TODO: USE grey darken-1 AS DYNAMIC INSTEAD */
  font-size: 0.9rem;
}

.notification-text-line {
  min-height: 1rem;
}
</style>