<template>
  <div class="challenges">
    <div v-if="categories.length > 0">
      <div class="card-wrapper" v-for="(challs, cat) in getCategorizedChalls" v-bind:key="cat">
        <h1 class="display-1">{{ cat }}</h1>
        <div class="card-container">
          <div v-for="chall in challs" v-bind:key="chall.id">
            <v-card class="minor-card challenge-card" :color="($store.state.dynamic.solves != null && $store.state.dynamic.solves.includes(chall.id)) ? 'primary' : ''" @click="displayModal(chall.id)">
              <v-card-title>
                {{ chall.name }}
                <v-spacer></v-spacer>
                {{ chall.points }}
              </v-card-title>
              <v-card-text class="card-text">{{ chall.solves }} Solves</v-card-text>
            </v-card>
          </div>
        </div>
      </div>
      <v-dialog v-model="dialog" width="700" internal-activator>
        <v-card v-if="dialogID">
          <v-card-title class="headline" primary-title>
          {{ $store.state.dynamic.challenges[dialogID].name }}
          <v-spacer></v-spacer>
          {{ $store.state.dynamic.challenges[dialogID].points }}
          </v-card-title>
          <v-card-text class="card-subtitle-split">
            <span>Author: {{ $store.state.dynamic.challenges[dialogID].author || "unknown" }}</span>
            <span>Category: {{ $store.state.dynamic.challenges[dialogID].category }}</span>
          </v-card-text>
          <v-card-text>
            <vue-simple-markdown class="chall-desc-override" :class="markdownChallDescription" :source="$store.state.dynamic.challenges[dialogID].description"></vue-simple-markdown>
          </v-card-text>
          <div v-if="$store.state.dynamic.challenges[dialogID].assets">
            <v-card-text v-if="$store.state.dynamic.challenges[dialogID].assets.length">
              <h3>Challenge File{{$store.state.dynamic.challenges[dialogID].assets.length > 1 ? 's' : ''}}:</h3>
              <div v-for="asset in $store.state.dynamic.challenges[dialogID].assets" v-bind:key="asset.url">
                <v-btn style="margin: 8px 0" block outlined color="primary" @click="getFile(asset.url, asset.name)">
                  <v-icon style="margin: 0 8px">mdi-download</v-icon>
                  {{ asset.name }}
                </v-btn>
              </div>
            </v-card-text>
          </div>
          <v-divider></v-divider>
          <div class="response-wrapper">
            <v-alert  v-bind:type="alertType" v-if="flagRespose">{{ flagRespose }}</v-alert>
          </div>
          <v-card-actions>
            <v-form class="flag-submit-form" @submit.prevent="submitFlag">
              <v-text-field hide-details :background-color="flagInputTheme" label="Flag" v-model="flagInput" solo></v-text-field>
              <v-btn class="flag-submit-bt" large color="primary" @click="submitFlag">Submit</v-btn>
            </v-form>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div v-else>
      <h1 class="display-1 text-center waiting-box"> {{ timeInfo }} </h1>
    </div>
  </div>
</template>

<script>
import api from '@/Requests.js'
import saveAs from 'file-saver'
export default {
    data: () => ({
      apiURL: api.url,
      flagInput: undefined,
      dialog: false,
      dialogID: undefined,
      flagRespose: undefined,
      alertType: "error",
      currentTime: new Date().getTime(),
      timeInfo: undefined,
    }),
    methods: {
      displayModal: function(chall) {
        this.dialogID = chall.toString()
        this.dialog = true
      },
      submitFlag: function () {
        if (this.flagInput == undefined || this.flagInput == "") {
          this.flagRespose = "Flag may not be empty"
          this.alertType = "warning"
        } else {
          api.post('/ctf/flag', {flag: this.flagInput, id: parseInt(this.dialogID)}, this.$store.state.token)
          .then(res => {
            this.flagInput = undefined
              this.flagRespose = res.message
            if (res.success == false) {
              this.alertType = "error"
            } else {
              // yay you got the flag
              this.alertType = "success"
            }
          })
        }
        },
        getFile: function (filepath, filename) {
          api.file(filepath, this.$store.state.token)
          .then(res => {
            saveAs(res, filename)
          })
          .catch(err => {
              this.alertType = "error"
              this.flagRespose = err.message
          })
        },
        forceCloseModal: function () {
          this.dialog = false
          this.flagRespose = undefined
          this.flagInput = undefined
          this.dialogID = undefined
        }
    },
    computed: {
      categories: function() {
        if (this.$store.state.dynamic.challenges != undefined) {
          let categoriesObject = {}
          for (let i = 0; i < Object.keys(this.$store.state.dynamic.challenges).length; i++) {
            let tmpCat = this.$store.state.dynamic.challenges[Object.keys(this.$store.state.dynamic.challenges)[i]].category
            if (!categoriesObject[tmpCat]) {
              categoriesObject[tmpCat] = true
            }
          }
          return Object.keys(categoriesObject).sort((a,b) => {
            a = a.toLowerCase()
            b = b.toLowerCase()
            if (a < b) return -1
            if (a > b) return 1
            return 0
          })
        }
        return []
      },
      getCategorizedChalls: function() {
        let sortedChallenges = {};
        // If a update to challenges is issued and it removes the challenge in our current dialog
        // we need to force close the dialog to not cause inconcistencies in the UI
        if (this.dialogID) {
          if (this.$store.state.dynamic.challenges[this.dialogID] == undefined) {
            // Computed properties should not have side effects such as updating variables
            // Therefore we outsource that job to a method. It's a hack but it works.
            this.forceCloseModal()
          }
        }
        if (this.$store.state.dynamic.challenges != undefined) {
          // Loop through existing categories
          for (let i = 0; i < this.categories.length; i++) {
            // Look for this category in the list of challenges
            for (let j = 0; j < Object.keys(this.$store.state.dynamic.challenges).length; j++) {
              // If a category doesn't exist, create it
              if (!sortedChallenges[this.categories[i]]) {
                sortedChallenges[this.categories[i]] = [];
              }
              // If a challenge belongs to the category i, add it to that object/list
              if (this.categories[i] == this.$store.state.dynamic.challenges[Object.keys(this.$store.state.dynamic.challenges)[j]].category) {
                sortedChallenges[this.categories[i]].push(this.$store.state.dynamic.challenges[Object.keys(this.$store.state.dynamic.challenges)[j]]);
              }
            }
          }
        }
        return sortedChallenges;
      },
      flagInputTheme: function() {
        if (this.$vuetify.theme.isDark) {
          return "#444"
        }
        return "#eee"
      },
      markdownChallDescription: function() {
        if (this.$vuetify.theme.isDark) {
          return "md-body md-body-dark"
        }
        return "md-body md-body-light"
      },
      timeCalc: function() {
          let prefix = ""
          let target = undefined
          let res = undefined

          if (this.$store.state.dynamic.state.manual) {
              if (this.$store.state.dynamic.state.active) return "No challenges are avilable";
              return "CTF is not active";
          }

          if (new Date(this.$store.state.dynamic.state.start_time).getTime() <= this.currentTime) {
            if (new Date(this.$store.state.dynamic.state.end_time).getTime() <= this.currentTime) return "CTF is not active";
            return "No challenges are avilable";
          }
          target = new Date(this.$store.state.dynamic.state.start_time).getTime()
          res = target - this.currentTime
          prefix = "Starts In "

          let days = Math.floor(res / (1000 * 60 * 60 * 24));
          let hours = Math.floor((res % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          let minutes = Math.floor((res % (1000 * 60 * 60)) / (1000 * 60));
          let seconds = Math.floor((res % (1000 * 60)) / 1000);

          let timerString = prefix

          if (days >= 1) {
              timerString += days
              if (days == 1) {
                  timerString += " day "
              } else {
                  timerString += " days "
              }
              timerString += "and "
          }
          // Pretty print
          timerString += (hours.toString().length == 1 ? "0" + hours : hours) + ":"
          timerString += (minutes.toString().length == 1 ? "0" + minutes : minutes) + ":"
          timerString += seconds.toString().length == 1 ? "0" + seconds : seconds
          return timerString
        },
    },
    watch: {
      dialog: function(newDialogState) {
        if (newDialogState == false) {
          this.flagRespose = undefined
          this.flagInput = undefined
          this.dialogID = undefined
        }
      }
    },
    created() {
      setInterval(() => {
        this.currentTime = new Date().getTime()
        this.timeInfo = this.timeCalc
      }, 1000)
    },
};
</script>

<style scoped>
.waiting-box {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
}

.theme--light .challenge-card.primary {
  color: #fff;
}

.theme--light .challenge-card.primary .card-text{
  color: #fff;
}

.card-subtitle-split {
  display: flex;
  justify-content: space-between;
}

.md-body-dark.chall-desc-override {
    padding: 0;
    color: #fff !important;
}

.md-body-light.chall-desc-override {
    padding: 0;
    color: #000 !important;
}

.flag-submit-form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-items: space-between;
}

.flag-submit-bt {
  margin-left: 0.5rem;
}

.response-wrapper {
  margin: 0.8rem 0.5rem -0.8rem 0.5rem;
}
</style>

<style>
pre code {
  display: inline-block;
}
</style>