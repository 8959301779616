<template>
    <div class="admin">
        <v-card class="major-card">
            <v-card-title>Send Notification</v-card-title>
            <v-card-text class="extra-padding">
                <v-form ref="notifyForm">
                    <v-text-field
                        required
                        outlined
                        label="Title"
                        ref="title"
                        v-model="title"
                        :rules="titleRule"
                        @focus="$refs.title.resetValidation()"
                    ></v-text-field>
                    <v-textarea
                        required
                        outlined
                        label="Notification text"
                        ref="msg"
                        v-model="notifyMessage"
                        :rules="notifyMessageRule"
                        @focus="$refs.msg.resetValidation()"
                    ></v-textarea>
                    <div class="text-right">
                        <v-btn large color="primary" @click="sendNotification()">Send Notification</v-btn>
                    </div>
                </v-form>
                <div class="notify-response-wrapper">
                    <v-alert :type="responseType" v-if="response">{{ response }}</v-alert>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import api from "@/Requests.js";
export default {
    name: "admin-notification",
    data: () => ({
        title: undefined,
        titleRule: [v => !!v || "Title is required"],
        notifyMessage: undefined,
        notifyMessageRule: [v => !!v || "A message is required"],
        response: false,
        responseType: "error"
    }),
    methods: {
        sendNotification: function() {
            if (!this.$refs.notifyForm.validate()) {
              return false
            }
            api.post(
                "/admin/notify",
                { title: this.title, message: this.notifyMessage },
                this.$store.state.token
            ).then(res => {
              this.response = res.message;
                if (res.success) {
                    this.title = undefined;
                    this.notifyMessage = undefined;
                    this.responseType = "success"
                    this.$refs.notifyForm.resetValidation();
                    return true;
                }
                this.responseType = "error"
            });
        }
    }
};
</script>

<style scoped>
.notify-response-wrapper {
  margin-top: 2rem
}
</style>