const url = process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : ""
const apiURL = url.endsWith('/') ? url + "api" : url + "/api"

const req = {
    get: (path, AuthToken = undefined) => {
        let requestHeaders = {
            "Content-Type": "application/json"
        }
        if (AuthToken) {
            requestHeaders["Authorization"] = "Bearer " + AuthToken
        }
        return fetch(apiURL + path, {
            headers: requestHeaders
        })
        .then(res => {
            // Ugly rate limiting hack
            if (res.status === 429) return alert('You are rate limited, please slow down!')
            return res.json()
        })
        .then(data => {
            return data
        })
        .catch(err => {
            /* eslint no-console: off */
            console.error(err)
            return {
                success: false,
                message: "A network error has occured"
            }
        })
    },

    file: (path, AuthToken = undefined) => {
        let requestHeaders = {
            "Content-Type": "application/json"
        }
        if (AuthToken) {
            requestHeaders["Authorization"] = "Bearer " + AuthToken
        }
        return fetch(url + path, {
            headers: requestHeaders
        })
        .then(async response => {
            // Ugly rate limiting hack
            if (response.status === 429) return alert('You are rate limited, please slow down!')
            if (response.status != 200) {
                let res = await response.json()
                throw new Error(res.message) 
            }
            return response.blob()
        })
        .then((blobject) => {
            return blobject
        })
        .catch(err => {
            throw {
                success: false,
                message: err
            }
        })
    },

    upload: (path, formData, AuthToken = undefined) => {
        let requestHeaders = {};
        if (AuthToken) {
            requestHeaders["Authorization"] = "Bearer " + AuthToken
        }
        return fetch(apiURL + path, {
            method: 'POST',
            headers: requestHeaders,
            body: formData
        })
        .then(res => {
            // Ugly rate limiting hack
            if (res.status === 429) return alert('You are rate limited, please slow down!')
            return res.json()
        })
        .then(data => {
            return data
        })
        .catch(err => {
            /* eslint no-console: off */
            console.error(err)
            return {
                success: false,
                message: "A network error has occured"
            }
        })
    },

    post: (path, requestBody, AuthToken = false) => {
        let requestHeaders = {
            "Content-Type": "application/json"
        }
        if (AuthToken) {
            requestHeaders["Authorization"] = "Bearer " + AuthToken
        }
        return fetch(apiURL + path, {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify(requestBody)
        })
        .then(res => {
            // Ugly rate limiting hack
            if (res.status === 429) return alert('You are rate limited, please slow down!')
            return res.json()
        })
        .then(data => {
            return data
        })
        .catch(err => {
            /* eslint no-console: off */
            console.error(err)
            return {
                success: false,
                message: "A network error has occured"
            }
        })
    },
    delete: (path, requestBody, AuthToken = undefined) => {
        let requestHeaders = {
            "Content-Type": "application/json"
        }
        if (AuthToken) {
            requestHeaders["Authorization"] = "Bearer " + AuthToken
        }
        return fetch(apiURL + path, {
            method: 'DELETE',
            headers: requestHeaders,
            body: JSON.stringify(requestBody)
        })
        .then(res => {
            // Ugly rate limiting hack
            if (res.status === 429) return alert('You are rate limited, please slow down!')
            return res.json()
        })
        .then(data => {
            return data
        })
        .catch(err => {
            /* eslint no-console: off */
            console.error(err)
            return {
                success: false,
                message: "A network error has occured"
            }
        })
    }
}

export default {
    url: url,
    apiURL: apiURL, 
    get: req.get, 
    post: req.post,
    file: req.file,
    upload: req.upload,
    delete: req.delete
}