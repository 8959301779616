<template>
  <div id="challenges-wrapper">
    <component v-bind:is="challengeFormat" />
  </div>
</template>
<script>
import cards from '@/views/ChallengesCards'
import list from '@/views/ChallengesList'
export default {
  name: 'Challenges',
  components: {
    cards,
     list
  },
  computed: {
      challengeFormat: function() {
          return this.$store.state.ctf_info.uiformat === 'list' ? list : cards;
      }
  }
}
</script>