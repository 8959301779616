<template>
  <div class="home">
    <v-card  class="medium-card">
        <v-card-title class="card-title-center">Login</v-card-title>
        <v-card-text>
            <v-form ref="loginForm">
                <v-text-field ref="usr" label="Username" v-model="username" :rules="usernameRule" required @focus="$refs.usr.resetValidation()"></v-text-field>
                <v-text-field ref="pwd" label="Password" v-model="password" :rules="passwordRule" required @focus="$refs.pwd.resetValidation()" type="password" @keydown.enter="tryLogin()"></v-text-field><br>
                <v-col class="text-right">
                    <v-btn @click="tryLogin()" :loading="isLoading">Login</v-btn>
                </v-col>
            </v-form>
        </v-card-text>
    </v-card>
    <v-alert class="medium-card" type="error" v-if="error"> {{error}} </v-alert>
  </div>
</template>

<script>
import api from '@/Requests.js'
export default {
  name: 'home',
  data: () => ({
      username: undefined,
      usernameRule: [v => !!v || "Username is required"],
      password: undefined,
      passwordRule: [v => !!v || "Password is required"],
      isLoading: false,
      error: false
    }),
  methods: {
      tryLogin: function () {
          if (!this.$refs.loginForm.validate()) {
              return false
          }
          this.isLoading = true
          let usrname = this.username
          api.post("/login", {"username": this.username, "password": this.password}).then(res => {
              this.password = undefined
            this.$refs.loginForm.resetValidation()
            this.isLoading = false
            if (!res.success) {
                this.error = res.message
              return false
            }
            this.username = undefined
            this.error = false
            this.$store.commit("setToken", {token: res.token, exp: res.expire, user: usrname, group: res.group})
            this.$store.dispatch('activateStreamingEventData', api.apiURL + this.$store.state.sse_connection);
            this.$router.push('/')
            
          })
      },
      resetValidation: function () {
          this.$refs.loginForm.resetValidation()
      }
  }
}
</script>

<style scoped>
.card-title-center {
    justify-content: center;
}
</style>
