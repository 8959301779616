import Vue from 'vue'
import Vuex from 'vuex'
import {EventSourcePolyfill} from 'event-source-polyfill'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    sse_connection: "/ctf/sse",
    token: window.localStorage.getItem('token') || undefined,
    token_exp: window.localStorage.getItem('exp') || undefined,
    username: window.localStorage.getItem('user') || undefined,
    group: window.localStorage.getItem('group') || undefined,
    ctf_info: {
      title: undefined,
      logo: undefined,
      greeting: undefined,
      rules: undefined,
      signup: undefined,
      uiformat: 'cards',
      versions: {
        api: undefined,
        client: process.env.VUE_APP_GITVERSION
      }
    },
    dynamic: {
      eventSource: undefined,
      scoreboard: undefined,
      challenges: undefined,
      solves: undefined,
      "first-blood": [],
      state: {
        start_time: undefined,
        end_time: undefined,
        state: undefined,
        active: undefined
      },
      notifications: [],
      readNotifications: JSON.parse(window.localStorage.getItem('readNotifications')) || [],
      "admin-scoreboard": undefined,
      stats: {
        goroutines: 0,
        sse: 0,
        totalmem: 0,
        cpu: 0,
        load1: 0,
        load5: 0,
        load15: 0,
        mem: 0,
        oscpu: 0,
        osmem: 0,
        ostotalmem: 0
      },
      logs: [],
      backoff: {
          delay: 1,
          tries: 0,
      }
    },
    options: {
      useDarkTheme: window.localStorage.getItem('useDarkTheme') === 'false' ? false : true,
      useNotifySound: window.localStorage.getItem('useNotifySound') === 'false' ? false : true,
      ScoreboardOnlyPrizeEligible: window.localStorage.getItem('ScoreboardOnlyPrizeEligible') === 'true' ? true : false // Default to false
    }
  },
  mutations: {
    addInfo(state, info) {
        state.ctf_info.title = info.title
        state.ctf_info.logo = info.image
        state.ctf_info.greeting = info.greeting
        state.ctf_info.rules = info.rules
        state.ctf_info.signup = info.signup
        state.ctf_info.versions.api = info.version
        state.ctf_info.uiformat = info.uiformat;
        state.dynamic.state.start_time = info.start_time
        state.dynamic.state.end_time = info.end_time
        state.enabled = info.enabled
    },
    initEvtSource(state, evtSourceURL) {
      state.dynamic.eventSource = new EventSourcePolyfill(evtSourceURL, {
        headers: {
          'Authorization': "Bearer " + state.token
        }
      })
    },
    eventSourceErrorListener(state, evtSourceURL) {
      state.dynamic.eventSource.addEventListener('error', () => {
        state.dynamic.backoff.tries++
        if (state.dynamic.backoff.tries == 5) {
          state.dynamic.backoff.delay = 5
        }
        else if (state.dynamic.backoff.tries == 10) {
          state.dynamic.backoff.delay = 10
        }
        else if (state.dynamic.backoff.tries == 15) {
          state.dynamic.backoff.delay = 30
        }
        console.log(
          "Connection attempt",
          state.dynamic.backoff.tries,
          "failed, sleeping for",
          state.dynamic.backoff.delay, "seconds"
        )
        setTimeout(() => {
          this.dispatch('initEvtSource', evtSourceURL)
          }, state.dynamic.backoff.delay * 1000)
      })
    },
    eventSoruceOpen(state) {
      state.dynamic.eventSource.addEventListener('open', () => {
        state.dynamic.backoff =  {
          delay: 1,
          tries: 0,
        }
      })
    },
    mutateDynamicData(state, eventObject) {
      state['dynamic'][eventObject.type] = JSON.parse(eventObject.data)
    },
    mutateChallengesObject(state, data) {
        let chall = JSON.parse(data.data)
        state.dynamic.challenges[chall.id] = chall
    },
    appendNotification(state, data) {
        let notification = JSON.parse(data.data)
        state.dynamic.notifications.unshift(notification)
    },
    appendRead(state, id) {
      state.dynamic.readNotifications.push(id)
      window.localStorage.setItem('readNotifications', JSON.stringify(state.dynamic.readNotifications))
    },
    appendFirstBlod(state, data) {
      let firstblood = JSON.parse(data.data);
      state.dynamic["first-blood"].push(firstblood);
    },
    appendLog(state, data) {
      let logEntry = JSON.parse(data.data)
      state.dynamic.logs.unshift(logEntry)
      if (state.dynamic.logs.length > 128) state.dynamic.logs.length = 128 
    },
    setEventListener(state, eventTypeAndTarget) {
      state['dynamic']['eventSource'].addEventListener(eventTypeAndTarget, event => {
        /* eslint no-console: warn */
        // console.log(event.type, "recieved")
        this.commit('mutateDynamicData', event)
      })
    },
    updateOneChallengeListener(state, eventType) {
      state.dynamic.eventSource.addEventListener(eventType, event => {
        /* eslint no-console: warn */
        // console.log(event.type, "recieved")
        this.commit('mutateChallengesObject', event)
      })
    },
    appendNotificationListener(state, eventType) {
      state.dynamic.eventSource.addEventListener(eventType, event => {
        /* eslint no-console: warn */
        // console.log(event.type, "recieved")
        this.commit('appendNotification', event)
      })
    },
    appendFirstBloodListener(state, eventType) {
      state.dynamic.eventSource.addEventListener(eventType, event => {
        this.commit('appendFirstBlod', event)
      })
    },
    appendLogListener(state, eventType) {
      state.dynamic.eventSource.addEventListener(eventType, event => {
        this.commit('appendLog', event)
      })
    },
    incrementNotifyCount(state, number) {
      state.dynamic.notifyCount = number
    },
    logout(state) {
        state.token = undefined
        state.expire = undefined
        state.group = undefined
        state.dynamic.notifications = []
        state.dynamic.readNotifications = []
        state.dynamic.eventSource.close()
        window.localStorage.removeItem('token')
        window.localStorage.removeItem('exp')
        window.localStorage.removeItem('user')
        window.localStorage.removeItem('group')
        window.localStorage.removeItem('readNotifications')

    },
    setToken(state, data) {
        state.token = data.token
        state.token_exp = data.exp
        state.username = data.user
        state.group = data.group
        window.localStorage.setItem('token', data.token)
        window.localStorage.setItem('exp', data.exp)
        window.localStorage.setItem('user', data.user)
        window.localStorage.setItem('group', data.group)
    },
    setDarkTheme(state, useDarkTheme) {
      state.options.useDarkTheme = useDarkTheme;
      window.localStorage.setItem('useDarkTheme', useDarkTheme);
    },
    setNotifySound(state, useNotifySound) {
      state.options.useNotifySound = useNotifySound;
      window.localStorage.setItem('useNotifySound', useNotifySound);
    },
    setScoreboardOnlyPrizeEligible(state, ScoreboardOnlyPrizeEligible) {
      state.options.ScoreboardOnlyPrizeEligible = ScoreboardOnlyPrizeEligible;
      window.localStorage.setItem('ScoreboardOnlyPrizeEligible', ScoreboardOnlyPrizeEligible);
    }
  },
  actions: {
    activateStreamingEventData(context, evtSourceURL) {
      context.commit('initEvtSource', evtSourceURL)
      context.commit('eventSourceErrorListener', evtSourceURL)
      context.commit('eventSoruceOpen')
      context.commit('setEventListener', "challenges")
      context.commit('setEventListener', "scoreboard")
      context.commit('setEventListener', "solves")
      context.commit('setEventListener', "state")
      context.commit('setEventListener', "keep-alive")
      context.commit('setEventListener', "notifications")
      context.commit('updateOneChallengeListener', "challenge")
      context.commit('appendNotificationListener', "notification")
      if (this.state.group == "admin") context.commit('setEventListener', "admin-scoreboard")
      if (this.state.group == "admin") context.commit('setEventListener', "stats")
      if (this.state.group == "admin") context.commit('appendLogListener', "log")
      context.commit('appendFirstBloodListener', "first-blood") //TODO: limit to ghosts: if (this.state.group == "ghost") 

    },
    fetchFirstBloodEvent(context) {
      return context.state.dynamic["first-blood"].pop();
    }
  },
  modules: {
  }
})
