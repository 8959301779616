<template>
  <v-app>
    <Navbar></Navbar>
    <router-view></router-view>
    <Footer></Footer>
  </v-app>
</template>

<script>
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import api from '@/Requests.js'

export default {
  name: "App",

  components: {
    Navbar,
    Footer
  },
  created() {
    this.$vuetify.theme.dark = this.$store.state.options.useDarkTheme;
    api.get("/").then(res => {
      this.$store.commit('addInfo', res);
    })
    const ceckValidToken = async () => {
      if (!this.$store.state.token) {
          return false
      }
        let now = Math.floor(Date.now() / 1000)
        let token_exp = parseInt(this.$store.state.token_exp)
        if (now >= (token_exp - 86400)) {
          if (token_exp <= now) {
          // force logout
          this.$store.commit('logout')
          this.$router.push('/')
          return false
          }
          // renew token
          await api.get("/refresh", this.$store.state.token)
          .then(res => {
          this.$store.commit("setToken", {token: res.token, exp: res.expire, user: this.$store.state.username, group: res.group})
          })
          .catch(() => {
          this.$store.commit('logout')
          this.$router.push('/')
          })
        }
    }
    ceckValidToken()
    .then(() => {
    if (this.$store.state.token !== undefined) {
        this.$store.dispatch('activateStreamingEventData', api.apiURL + this.$store.state.sse_connection);
    }
    })
    setInterval(() => {
    ceckValidToken()
    }, 1000 * 60 * 15)
  }
};
</script>

<style>
.major-card {
    width:80vw;
    margin:2.5rem auto;
}

.medium-card {
    width: 40vw;
    margin: 2.5rem auto;
}

.minor-card {
    width: 20vw;
    margin: 1rem;
}

.card-wrapper {
    margin: 2.5rem;
}

.d1-title {
  margin-top: 2.5rem;
}

.center-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
}

/* BEGIN MARKDOWN STYLE BLOCK */
.markdown-body {
    font-family: "Roboto", sans-serif !important;
    padding: 1rem;
    font-size: 0.875rem;
}

.md-body-dark {
    color: rgba(255, 255, 255, 0.7) !important;
}

.md-body-light {
    color: rgba(0, 0, 0, 0.6) !important;
}

.md-body h1 {
    padding: 16px 16px 16px 0;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    font-size: 1.25rem;
    line-height: 2rem;
    letter-spacing: 0.0125em;
}

.md-body h2,
.md-body h3,
.md-body h4,
.md-body h5,
.md-body h6 {
    padding: 8px 16px 8px 0;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    line-height: 2rem;
    letter-spacing: 0.0125em;
}

.md-body-dark h1 {
    color: #fff;

}

.md-body-light h1 {
    color: #000;

}

.md-body-dark h2,
.md-body-dark h3,
.md-body-dark h4,
.md-body-dark h5,
.md-body-dark h6 {
    color: #fff;
}

.md-body-light h2,
.md-body-light h3,
.md-body-light h4,
.md-body-light h5,
.md-body-light h6 {
    color: #000;
}

.md-body ul {
    padding-left: 1rem !important;
    padding-bottom: 1rem;
}

.md-body .hljs::before {
  content: "";
}

.md-body .hljs {
  width: 100%;
  margin-top: 16px;
  padding: 3px 8px;
  line-height: 1.3rem;
}

.md-body-dark code {
  font-weight: 300 !important;
  background-color: #444 !important;
  color: #fff !important;
}

.md-body-light code {
  font-weight: 300 !important;
  color: #000 !important;
}
/* END MARKDOWN STYLE BLOCK */
</style>