<template>
    <div id="rules">
        <v-card  class="major-card">
            <vue-simple-markdown :class="markdowntheme" :source="$store.state.ctf_info.rules"></vue-simple-markdown>
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'Rules',
    computed: {
        markdowntheme: function() {
            if (this.$vuetify.theme.isDark) {
                return "md-body md-body-dark"
            }
            return "md-body md-body-light"
        }
    }
};
</script>
