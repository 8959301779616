<template>
  <div class="admin-scoreboard">
    <v-card class="major-card" loading=true>
        <v-card-title>
            Accounts
            <v-spacer></v-spacer>
            <v-btn outlined color="primary" class="btn-create" @click="showCreateDialog()">Create Account</v-btn>
            <v-btn min-width="102px" v-if="!refreshBtnIcon" color="primary" @click="refresh()">Refresh</v-btn>
            <v-btn min-width="102px" v-else color="primary" disabled>
                <v-icon>mdi-check</v-icon>
            </v-btn>
        </v-card-title>
        <div v-if="error">
            <v-card-text>
                <v-alert type="error">{{ error }}</v-alert>
            </v-card-text>
        </div>
      <v-data-table
        :headers="headers"
        :items="accounts"
        :items-per-page="-1"
        hide-default-footer
        sort-by="id"
        item-key="id"
        class="elevation-1"
      >
        <!-- eslint-disable-next-line vue/valid-v-slot -->
        <template v-slot:item.prize_eligible="account">
          <v-icon v-if="account.item.prize_eligible" size="1.2rem">mdi-trophy</v-icon>
          <v-icon v-else size="1.2rem">mdi-close</v-icon>
        </template>
        <!-- eslint-disable-next-line vue/valid-v-slot -->
        <template v-slot:item.edit="account">
          <v-btn text @click="displayModal(account.item)">
            <v-icon>mdi-account-edit-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialog" persistent width="700" internal-activator>
        <v-card v-if="dialogData">
            <v-card-title>Edit Account</v-card-title>
            <v-card-text>
                <v-text-field label="Name" v-model="dialogData.username"></v-text-field>
                <v-text-field label="Email" v-model="dialogData.email"></v-text-field>
                <v-text-field label="Password" type="password" v-model="dialogData.password"></v-text-field>
                <v-select label="Prize Eligable" :items="prizeable" v-model="dialogData.prize_eligible"></v-select>
                <v-select label="Group" :items="groups" v-model="dialogData.group"></v-select>
                <v-text-field label="Association" v-model="dialogData.association"></v-text-field>
                <div v-if="dialogError">
                  <v-alert :type="dialogErrorType">{{ dialogError }}</v-alert>
                </div>
            </v-card-text>
            <v-card-actions>
              <v-btn large outlined color="error" @click="DeleteAccount()">Delete</v-btn>
              <v-spacer></v-spacer>
              <v-btn large text @click="dialog = false">Cancel</v-btn>
              <v-btn color="primary" large @click="saveAccountData()" :loading="isLoading">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="createDialog" persistent width="700" internal-activator>
        <v-card v-if="dialogData">
            <v-card-title>Create Account</v-card-title>
            <v-card-text>
                <v-text-field label="Name" v-model="createAccountData.username"></v-text-field>
                <v-text-field label="Email" v-model="createAccountData.email"></v-text-field>
                <v-text-field label="Password" type="password" v-model="createAccountData.password"></v-text-field>
                <v-text-field label="Confirm password" type="password" v-model="createAccountData.confirmPassword"></v-text-field>
                <v-select label="Prize Eligable" :items="prizeable" v-model="createAccountData.prize_eligible"></v-select>
                <v-select label="Group" :items="groups" v-model="createAccountData.group"></v-select>
                <v-text-field label="Association" v-model="createAccountData.association"></v-text-field>
                <div v-if="createDialogError">
                  <v-alert :type="createDialogErrorType">{{ createDialogError }}</v-alert>
                </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large text @click="createAccountData = {}; createDialog = false">Cancel</v-btn>
              <v-btn color="primary" large @click="createAccount()" :loading="isLoading">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    </div>
</template>

<script>
import api from '@/Requests.js'
export default {
    name: "admin-scoreboard",
    data: () => ({
        accounts: undefined,
        headers: [
          {
            text: 'Name',
            value: 'username'
          },
          {
            text: 'Email',
            value: 'email'
          },
          {
            text: 'Prize Eligible',
            value: 'prize_eligible'
          },
          {
            text: 'Association',
            value: 'association'
          },
          {
            text: 'Group',
            value: 'group'
          },
          {
            text: 'Edit',
            align: 'right',
            value: 'edit'
          }
        ],
        refreshBtnIcon: false,
        groups: undefined,
        prizeable: [true, false],
        dialog: false,
        originalAccountData: {},
        dialogData: {},
        error: false,
        isLoading: false,
        dialogError: false,
        dialogErrorType: "error",
        createDialog: false,
        createAccountData: {},
        createDialogError: false,
        createDialogErrorType: "error"
    }),
    created() {
      api.get("/admin/accounts", this.$store.state.token).then(res => {
        if (!res.success) {
            this.error = res.message
            return false
        }
        this.accounts = res.accounts
      })
    },
    methods: {
        refresh: function() {
            api.get("/admin/accounts", this.$store.state.token).then(res => {
                if (!res.success) {
                    this.error = res.message
                    return false
                }
                this.error = false
                this.accounts = res.accounts
                this.refreshBtnIcon = true
                setTimeout(() => {
                    this.refreshBtnIcon = false
                }, 2000);
            })
        },
        displayModal: function(account) {
          this.dialogError = false
          api.get("/admin/groups", this.$store.state.token).then(res => {
            if (!res.success) {
              this.error = res.message
              return false
            }
            this.error = false
            this.groups = res.groups
            account.password = undefined
            this.originalAccountData = account
            Object.assign(this.dialogData, account)
            this.dialog = true
          })
        },
        showCreateDialog: function () {
          api.get("/admin/groups", this.$store.state.token).then(res => {
            if (!res.success) {
              this.error = res.message
              return false
            }
            this.createDialogError = false
            this.groups = res.groups
            this.createDialog = true
          })
        },
        findDiff: function () {
          /**
          * First we figue out what has changed, aka a diff
          * This will be done by a loop and if it differs push it to an array
          * After that we check password which is a unique field.
          */
          let modifiedData = {}
          Object.keys(this.originalAccountData).forEach(key => {
            // Check all regular keys
            if (this.originalAccountData[key] != this.dialogData[key]) {
              modifiedData[key] = this.dialogData[key]
            }
          });
          // Check password field
          if (this.dialogData.password != undefined) {
            if (this.dialogData.password != "") {
            modifiedData.password = this.dialogData.password
            }
          }
          return modifiedData
        },
        createAccount: function() {
          if(this.createAccountData.password != this.createAccountData.confirmPassword) {
            this.createDialogError = "Passwords must match"
            this.createDialogErrorType = "warning"
            return false
          }
          this.isLoading = true
            api.post("/admin/createaccount", this.createAccountData, this.$store.state.token)
            .then(res => {
              this.isLoading = false
              if (res.success) {
                this.createAccountData = {}
                this.createDialog = false
                this.refresh()
                return true
              }
              this.createDialogError = res.message
              this.createDialogErrorType = "error"
            })
        },
        saveAccountData: function() {
            /**
             * Using our diff, we send the update POST request
             * If it fails we display an error within the modal
             * If it succeeds we close the modal and call a refresh
            */
          let diff = this.findDiff()
          if (diff == false) {
            return false
          }
          diff.id = this.originalAccountData.id
          //POST changed data
          this.isLoading = true
          api.post("/admin/updateaccount", diff, this.$store.state.token)
          .then(res => {
            this.isLoading = false
            if (res.success) {
              if (res.saved) {
                this.dialog = false
                this.refresh()
                return true
              }
              this.dialogError = res.message
              this.dialogErrorType = "warning"
              return false
            }
            this.dialogError = res.error ? res.message + ". Reason: " + res.error : res.message
            this.dialogErrorType = "error"
          })
        },
        DeleteAccount: function () {
          api.post("/admin/deleteaccount", {"id": this.originalAccountData.id}, this.$store.state.token)
          .then(res => {
            if (res.success) {
              if (res.saved) {
                this.dialog = false
                this.refresh()
                return true
              }
              this.dialogError = res.message
              this.dialogErrorType = "warning"
              return false
            }
            this.dialogError = res.error ? res.message + ". Reason: " + res.error : res.message
            this.dialogErrorType = "error"
          })
        }
    }
}
</script>

<style scoped>
.btn-create {
  margin-right: 1rem;
}
</style>