<template>
  <div class="admin-scoreboard">
    <v-card class="major-card">
      <div class="scoreboard-toggle">
        <v-switch v-model="reveal" label="Show hidden table data" color="error"></v-switch>
      </div>
        <v-simple-table>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th>Place</th>
                            <th>Team</th>
                            <th>Score</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="team in $store.state.dynamic['admin-scoreboard']" v-bind:key="team.name">
                            <td>
                              {{ team.place }}
                              <v-icon style="padding-left:8px" v-if="team.prize_eligible" size="1.2rem">mdi-trophy</v-icon>
                            </td>
                            <td>{{ reveal ? team.username : '???' }}</td>
                            <td>{{ reveal ? team.score : '???' }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
    </v-card>
    </div>
</template>

<script>
export default {
    name: "admin-scoreboard",
    data: () => ({
        reveal: false
    })
}
</script>

<style scoped>
.scoreboard-toggle {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0.5rem 0;
}
</style>