<template>
  <div class="admin">
    <div class="major-split">
      <v-card class="major-card split-part left">
        <v-card-title>CTF Activity</v-card-title>
        <v-card-text class="text-rows" v-if="$store.state.dynamic.logs">
          <div v-if="$store.state.dynamic.logs.length">
            <div v-for="(log, i) in $store.state.dynamic.logs" v-bind:key="i">
              <div class="log-row">
                <div class="log-event">
                  <span class="chip" :class="log.type">{{log.type}}</span>
                  <div>{{log.message}}</div>
                </div>
                <div class="timestamp">
                  {{ new Date(log.timestamp).toLocaleString() }}
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <i>No activity yet</i>
          </div>
        </v-card-text>
      </v-card>
      <div class="split-part right">
        <div class="upper">
          <v-card class="major-card">
            <v-card-title>SSE Connections</v-card-title>
            <v-card-text style="text-align:center">
              <h1 class="display-3">{{$store.state.dynamic.stats.sse}}</h1>
            </v-card-text>
          </v-card>
          <v-card class="major-card">
            <v-card-title>Ram Usage</v-card-title>
            <v-card-text style="text-align:center">
              <h1 class="display-1">{{formatBytes($store.state.dynamic.stats.mem)}} / <span style="font-weight:300">{{formatBytes($store.state.dynamic.stats.ostotalmem)}}</span></h1>
              <h3 style="padding-top: 1rem;font-weight:400">OS Usage: {{formatBytes($store.state.dynamic.stats.osmem)}}</h3>
            </v-card-text>
          </v-card>
        </div>
        <v-card class="major-card lower">
          <v-card-title>Statistics</v-card-title>
          <v-card-text>
            <div class="data-row">
              <span class="data-field">
                <h1 class="display-3">{{$store.state.dynamic.stats.goroutines}}</h1>
                <span>Goroutines</span>
              </span>
              <span class="data-field">
                <h1 class="display-3">{{$store.state.dynamic.stats.cpu.toFixed(1)}}%</h1>
                <span>CPU Usage</span>
              </span>
            </div>
            <v-divider style="margin: 2rem 0;"></v-divider>
            <div class="data-row">
              <span class="data-field">
                <h1 class="display-3">{{$store.state.dynamic.stats.load1.toFixed(2)}}</h1>
                <span>Load Avg. 1 min</span>
              </span>
              <span class="data-field">
                <h1 class="display-3">{{$store.state.dynamic.stats.oscpu.toFixed(1)}}%</h1>
                <span>OS CPU Usage</span>
              </span>
            </div>
            <!-- Chart here -->
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
// import api from '@/Requests.js'
export default {
    name: "admin-scoreboard",
    data: () => ({
      logs: []
    }),
    methods: {
      formatBytes: function (bytes, decimals = 1) {
            if (bytes === 0) return '0 Bytes';

            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

            const i = Math.floor(Math.log(bytes) / Math.log(k));

            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        }
    }
}
</script>

<style scoped>

.log-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(128, 128, 128, 0.5);
}

.log-row .log-event {
  display: flex;
  align-items: center;
}

.log-row:hover {
  background: rgba(128, 128, 128, 0.2);
}

.log-row .chip {
  min-width: 48px;
  margin: 8px;
  padding: 2px 4px;
  color: #fff;
  border-radius: 3px;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
}

.log-row .solve {
  background: #2E7D32; /* Material Design Green 800 */
}

.log-row .fail {
  background: #C62828; /* Material Design Red 800 */
}

.log-row .login {
  background: #1565C0; /* Material Design Blue 800 */
}

.log-row .blood {
  background: #6A1B9A; /* Material Design Purple 800 */
}

.log-row .timestamp {
  padding: 0 8px;
  opacity: 0;
}

.log-row:hover .timestamp {
  opacity: 1;
}

.text-rows {
  overflow-y: scroll;
  max-height: 85%;
}

.major-split {
  display: flex;
  flex-shrink: 0;
  margin: 0 1.5rem;
  justify-content: center;
  height: 80vh;
}

.major-split .left {
  margin-right: .5rem;
  width: 50%;
}

.major-split .right {
  margin-left: .5rem;
  width: 50%;
}

.major-split .right .upper {
  margin: 2.5rem 0 0 0;
  display: flex;
}

.major-split .right .upper .major-card:first-child{
  margin: 0;
  margin-right: .5rem;
}

.major-split .right .upper .major-card:last-child{
  margin: 0;
  margin-left: .5rem;
}

.major-split .right .lower {
  margin-top: 1rem;
}

.data-row {
  display: flex;
  justify-content: space-evenly;
}

.data-row .data-field {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
}
</style>