<template>
  <div class="home">
      <div class="ctf-logo-wrapper" v-if="$store.state.ctf_info.logo">
        <img :src="logoURL" alt="CTF Logo" class="ctf-logo">
      </div>
    <v-card  class="major-card">
        <vue-simple-markdown :class="markdowntheme" :source="$store.state.ctf_info.greeting"></vue-simple-markdown>
    </v-card>
  </div>
</template>

<script>
import api from '@/Requests.js'
export default {
    name: 'Home',
    data: () => ({
        logoURL: api.apiURL + '/logo'
    }),
    computed: {
        markdowntheme: function() {
            if (this.$vuetify.theme.isDark) {
                return "md-body md-body-dark"
            }
            return "md-body md-body-light"
        }
    }
};
</script>

<style scoped>
.ctf-logo-wrapper {
    text-align: center;
}

.ctf-logo {
    height: 300px;
    margin: 3rem auto 0 auto;
}
</style>
