<template>
  <div class="settings">
    <v-card class="major-card">
      <v-card-title>
        Settings
        <v-spacer></v-spacer>
        <v-btn min-width="102px" :disabled="stickyAlert.display || refreshBtnPressed" color="primary" @click="refresh()">
          <span v-if="!refreshBtnPressed">Refresh</span>
          <v-icon v-else>mdi-check</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-card-title class="inner-card-title">Info</v-card-title>
        <!-- Title, image, greeting, and rules -->
          <v-text-field required outlined label="CTF Title" v-model="dynamicSettings.title" @keyup="checkForUnsavedSettings()"></v-text-field>
        <!-- Image upload, handled differently than the rest of the settings -->
        <v-row class="image-upload-wrap">
          <v-file-input accept="image/*" v-model="imagelogo" filled label="CTF Logo" persistent-hint hint="Press upload to save the new logo"></v-file-input>
          <v-btn large color="primary" class="image-upload-button" v-if="imagelogo == 0 || imagelogo == undefined" disabled>Upload</v-btn>
          <v-btn large color="primary" class="image-upload-button" v-else @click="uploadImage">Upload</v-btn>
        </v-row>

        <!-- CTF logo toggle -->
        <v-row no-gutters style="margin-bottom:1rem">
          <v-switch v-model="dynamicSettings.displaylogo" label="Display CTF logo" @change="checkForUnsavedSettings()"></v-switch>
        </v-row>

        <v-textarea required outlined label="CTF Description (markdown)" v-model="dynamicSettings.greeting" @keyup="checkForUnsavedSettings()"></v-textarea>
        <v-textarea required outlined label="CTF Rules (markdown)" v-model="dynamicSettings.rules" @keyup="checkForUnsavedSettings()"></v-textarea>
        <v-divider></v-divider>
        <!-- start, stop, enable stop -->
        <v-card-title class="inner-card-title">Start, Stop & Time</v-card-title>
        <v-datetime-picker label="Start Time" v-model="dynamicStartTime" :time-picker-props="timePropSettings" eventIcon="mdi-calendar" accessIcon="mdi-clock"> </v-datetime-picker>
        <!-- TODO: WHY IS IT PRINTED HERE? -->
        <!-- {{dynamicSettings.stop_time}} -->
        <v-datetime-picker label="Stop Time" v-model="dynamicEndTime" :time-picker-props="timePropSettings" eventIcon="mdi-calendar" accessIcon="mdi-clock"> </v-datetime-picker>
        <v-divider></v-divider>
        <!-- CTF Activity -->
        <v-card-title class="inner-card-title">CTF State</v-card-title>
        <v-switch v-model="dynamicSettings.active" label="CTF Active" @change="checkForUnsavedSettings()"></v-switch>
        <v-switch v-model="dynamicSettings.manual" label="Manual Mode" @change="checkForUnsavedSettings()"></v-switch>
        <v-switch v-model="dynamicSettings.enable_signup" label="Allow Signups" @change="checkForUnsavedSettings()"></v-switch>
        <v-switch v-model="dynamicSettings.caseinsensitiveflag" label="Case insensitive flags" @change="checkForUnsavedSettings()"></v-switch>
        <br> <!-- TODO: Make this a margin or something -->
        <v-text-field type="number" v-model.number="dynamicSettings.scoreboard_hidden" @input="checkForUnsavedSettings()" required outlined label="Number of hidden entries on the scoreboard"></v-text-field>
        <v-divider></v-divider>
        <!-- Decay settings -->
        <v-card-title class="inner-card-title">Scoring</v-card-title>
        <v-text-field required outlined type="number" label="Maximum Points" v-model.number="dynamicSettings.max_points" @input="checkForUnsavedSettings()"></v-text-field>
        <v-text-field required outlined type="number" label="Minimum Points" v-model.number="dynamicSettings.min_points" @input="checkForUnsavedSettings()"></v-text-field>
        <v-text-field required outlined type="number" label="Decay rate" v-model.number="dynamicSettings.decay" @input="checkForUnsavedSettings()"></v-text-field>
      </v-card-text>
    </v-card>
    <v-card class="major-card">
        <v-card-title>Reload data</v-card-title>
        <v-card-text>
          <v-btn width="100%" color="primary" :loading="assetsReloadInProgress" large outlined @click="reloadAssets()">Reload Assets (safe)</v-btn>
          <br><br>
          <v-btn width="100%" color="primary" :loading="challengeReloadInProgress" large outlined  @click="reloadChallenges(false)">Reload Challenges (safe)</v-btn>
        </v-card-text>
        <v-card-title>Danger Zone</v-card-title>
        <v-card-text>
          <v-dialog width="500" v-model="dialogReload">
              <template v-slot:activator="{ on, attrs }">
                  <v-btn width="100%" large outlined color="red" :loading="forceReloadInProgress" v-bind="attrs" v-on="on">Force reload challenges (destructive)</v-btn>
              </template>
              <v-card>
                  <v-card-title>Extended Validation</v-card-title>
                  <v-card-text>
                    This will overwrite any changed made to already existing challenges. THIS CANNOT BE UNDONE!
                  </v-card-text>
                  <v-card-actions>
                    <v-btn class="submit-bt" block large color="red darken-3" :loading="forceReloadInProgress" @click="reloadChallenges(true)">Confirm Reload</v-btn>
                  </v-card-actions>
              </v-card>
          </v-dialog>
          <br><br>
          <v-divider></v-divider>
          <br>
            <v-dialog width="500" v-model="dialog">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn width="100%" large color="red" v-bind="attrs" v-on="on">RESET ALL SOLVES</v-btn>
                </template>
                <v-card>
                    <v-card-title>Extended Validation</v-card-title>
                    <v-card-text>
                      This will permanently delete all solves and scores for all users. THIS CANNOT BE UNDONE!
                    </v-card-text>
                    <v-card-actions>
                      <v-btn class="submit-bt" block large color="red darken-3" @click="resetAllSolves()">Confirm Deletion</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card-text>
    </v-card>
    <br><br>
    <div class="settings-alert-wrap" v-if="stickyAlert.display">
      <v-alert :type="stickyAlert.type" class="rich-content-alert">
        <v-row class="alert-interactive-content">
          <span class="settings-alert-text">{{stickyAlert.text}}</span>
          <v-spacer></v-spacer>
          <span v-if="stickyAlert.type == 'warning'">
            <v-btn text depressed @click="refresh()">Discard</v-btn>&nbsp;
            <v-btn color="orange darken-4" depressed @click="saveSettings()">Save</v-btn>
          </span>
          <span v-else>
            <v-btn text @click="stickyAlert.display = false">Dismiss</v-btn>
          </span>
        </v-row>
        </v-alert>
    </div>
  </div>
</template>

<script>
import api from "@/Requests.js";
export default {
  data () {
    return {
      imagelogo: [],
      dynamicSettings: {},
      dynamicStartTime: undefined,
      dynamicEndTime: undefined,
      settings: {},
      timeSettings: {},
      timePropSettings: {
        format: "24hr"
      },
      // picker: new Date().toISOString().substr(0, 10),
      stickyAlert: {
        display: false,
        color: undefined,
        text: undefined,
        type: undefined
      },
      refreshBtnPressed: false,
      dialog: false,
      dialogReload: false,
      assetsReloadInProgress: false,
      challengeReloadInProgress: false,
      forceReloadInProgress: false
    }
  },
  created() {
      this.getSettings()
  },
  methods: {
    getSettings: function() {
      api.get("/admin/settings", this.$store.state.token)
      .then(res => {
        if (!res) {
          return false
        }
        this.displaySettings(res)
      })
    },
    displaySettings: function (settingsObject) {
      this.settings = settingsObject
      this.timeSettings.start_time = new Date(this.settings.start_time)
      this.timeSettings.end_time = new Date(this.settings.end_time)
      delete this.settings.start_time
      delete this.settings.end_time
      for (let i = 0; i < Object.keys(this.settings).length; i++) {
        this.dynamicSettings[Object.keys(this.settings)[i]] = this.settings[Object.keys(this.settings)[i]]
      }
      this.dynamicStartTime = this.timeSettings.start_time
      this.dynamicEndTime = this.timeSettings.end_time
      // TODO: This forces a redraw which is what i suspect causes the form to correctly display
      // It's data. This should not be here, in dire need of improvment.
      this.displayUnsavedChanges(false)
    },
    displayUnsavedChanges: function(state) {
      this.stickyAlert = {
          display: state,
          type: "warning",
          color: "orange darken-4",
          text: "You have unsaved changes"
        }
    },
    checkForUnsavedSettings: function() {
      for (let i = 0; i < Object.keys(this.settings).length; i++) {
        if (this.settings[Object.keys(this.settings)[i]] != this.dynamicSettings[Object.keys(this.settings)[i]]) {
          this.displayUnsavedChanges(true)
          return true
        }
      }

      if (this.dynamicStartTime != null) {
        if (this.dynamicStartTime.getTime() != this.timeSettings.start_time.getTime()) {
          this.displayUnsavedChanges(true)
          return true
        }
      }
      if (this.dynamicEndTime != null) {
        if (this.dynamicEndTime.getTime() != this.timeSettings.end_time.getTime()) {
          this.displayUnsavedChanges(true)
          return true
        }
      }
      this.displayUnsavedChanges(false)
    },
    refresh: function() {
      this.refreshBtnPressed = true
      setTimeout(() => {
          this.refreshBtnPressed = false
      }, 2000);
      this.getSettings()
    },
    saveSettings: function() {
      let settingsToSend = {}
      for (let i = 0; i < Object.keys(this.settings).length; i++) {
        if (this.settings[Object.keys(this.settings)[i]] != this.dynamicSettings[Object.keys(this.settings)[i]]) {
          settingsToSend[Object.keys(this.settings)[i]] = this.dynamicSettings[Object.keys(this.settings)[i]]
        }
      }
      if (this.dynamicStartTime) {
        if (this.dynamicStartTime.getTime() != this.timeSettings.start_time.getTime()) {
          settingsToSend["start_time"] = this.dynamicStartTime
        }
      }
      if (this.dynamicEndTime) {
        if (this.dynamicEndTime.getTime() != this.timeSettings.end_time.getTime()) {
          settingsToSend["end_time"] = this.dynamicEndTime
        }
      }
      api.post("/admin/settings", settingsToSend, this.$store.state.token)
      .then(res => {
        if (res.success && res.saved) {
          this.displaySettings(res.settings)
        } else {
          this.stickyAlert = {
            text: res.message,
            type: "error",
            display: true
          }
        }
      })
    },
    uploadImage: function() {
      if (this.imagelogo == undefined) {
        return false;
      }
      
      let form = new FormData();
      form.append('logo', this.imagelogo, this.imagelogo.name);

      api.upload('/admin/settings', form, this.$store.state.token)
      .then(res => {
        if (res.saved && res.success) {
          this.imagelogo = [];
          //TODO: DISPLAY SUCCESS
          return true;
        }
        this.stickyAlert =  {
            text: res.message,
            type: "error",
            display: true
          }

        //TODO: add error case for image upload
      });
    },
    resetAllSolves: function() {
      api.post('/admin/resetsolves', {}, this.$store.state.token).then(res => {
        this.dialog = false
        if (!res.success) this.stickyAlert = {
          display: true,
          type: 'error',
          text: res.error || res.message || 'Failed to reset solves'
        }
        else this.stickyAlert = {
          display: true,
          type: 'info',
          text:  res.message || 'Solves has been reset, I hope you meant to do that!'
        }
      });
    },
    reloadAssets: function() {
      this.assetsReloadInProgress = true
      api.post('/admin/reloadassets', {}, this.$store.state.token)
      .then(res => {
        this.assetsReloadInProgress = false
        this.stickyAlert = {
            text: res.message,
            type: res.success ? 'info' : 'error',
            display: true
          }
      })
    },
    reloadChallenges: function(force) {
      this[force ? 'forceReloadInProgress' : 'challengeReloadInProgress'] = true
      api.post('/admin/reloadchallenges', {overwrite: force}, this.$store.state.token)
      .then(res => {
        if (force) this.dialogReload = false
        this[force ? 'forceReloadInProgress' : 'challengeReloadInProgress'] = false
        this.stickyAlert = {
            text: res.message,
            type: res.success ? 'info' : 'error',
            display: true
          }
      })
    }
  },
  computed: {
    EVInputTheme: function() {
        if (this.$vuetify.theme.isDark) {
            return "#444"
        }
        return "#eee"
      },
  },
  watch: {
    dynamicStartTime: function() {
      this.checkForUnsavedSettings()
    },
    dynamicEndTime: function() {
      this.checkForUnsavedSettings()
    }
  }
}
</script>

<style scoped>
.inner-card-title {
  padding: 0;
  margin: 1.5rem 0;
}

.image-upload-wrap {
  margin: 1rem auto;
  display: flex;
  align-items: baseline;
}

.image-upload-button {
  margin: 0 1rem;
}

.settings-alert-wrap {
  position: fixed;
  bottom: 2rem;
  left: 2rem;
  right: 2rem;
}

.settings-alert-text {
  display: flex;
  align-items: center;
}

.single-line-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.single-line-wrap-form .v-text-field__details {
  display: none;
}

.alert-interactive-content {
  padding: 8px;
}
/* .EV-submit-form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-items: space-between;
}

.EV-submit-form 

.EV-submit-form .v-input__slot {
  margin: 0;
}

.EV-submit-bt {
  margin-left: 0.5rem;
} */

.response-wrapper {
  margin: 0.8rem 0.5rem -0.8rem 0.5rem;
}
</style>