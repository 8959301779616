<template>
  <div class="admin">
    <v-card class="major-card">
      <v-card-title>
        Schedules page
        <v-spacer></v-spacer>
        <v-btn :disabled="getSelectedChallenges.length === 0" outlined color="primary" class="btn-schedule" @click="showScheduleDialog()">Set Schedule</v-btn>
        <v-btn min-width="102px" v-if="!refreshBtnIcon" color="primary" @click="refresh()">Refresh</v-btn>
        <v-btn min-width="102px" v-else color="primary" disabled>
          <v-icon>mdi-check</v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="challenges"
        :items-per-page="-1"
        hide-default-footer
        show-select
        item-key="id"
        sort-by="id"
      >
        <!-- eslint-disable-next-line vue/valid-v-slot -->
        <template v-slot:item.data-table-select="challenge">
          <!-- <v-checkbox  v-bind="props" v-on="on"></v-checkbox> -->
          <v-checkbox v-model="selected[challenge.item.id]" :disabled="challenge.item.enabled"></v-checkbox>
        </template>
        <!-- eslint-disable-next-line vue/valid-v-slot -->
        <template v-slot:item.enabled="challenge">
          <div v-if="challenge.item.scheduled" class="status-wrap">
            <div class="circle amber"></div>
            {{challenge.item.scheduled}}
          </div>
          <div v-else-if="challenge.item.enabled" class="status-wrap">
            <div class="circle green"></div>
            Active
          </div>
          <div v-else class="status-wrap">
            <div class="circle grey"></div>
            Inactive
          </div>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="scheduleDialog" width="700" internal-activator>
        <v-card v-if="dialogData">
            <v-card-title>Set Schedule</v-card-title>
            <v-card-text>
              <div style="display:flex;">
                <v-date-picker style="border: 1px solid grey" v-model="datepicker"></v-date-picker>
                <v-time-picker style="border: 1px solid grey" v-model="timepicker" format="24hr" scrollable></v-time-picker>
              </div>
              <br><br>
                <div v-if="scheduleDialogError">
                  <v-alert type="error" >{{ scheduleDialogError }}</v-alert>
                </div>
            </v-card-text>
            <v-card-actions>
              <v-btn color="error" @click="removeSchedule()">Remove schedule</v-btn>
              <v-spacer></v-spacer>
              <v-btn large text @click="scheduleDialog = false">Cancel</v-btn>
              <v-btn color="primary" large :disabled="!datepicker || !timepicker" @click="setSchedule()" :loading="isLoading">Set Schedule</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from "@/Requests.js";
export default {
  name: "Schedules",
  data: () => ({
    chkboxState: {},
    refreshBtnIcon: false,
    selected: {},
    dialog: false,
    dialogData: {},
    isLoading: false,
    scheduleDialog: false,
    scheduleData: {},
    scheduleDialogError: false,
    scheduleTime: null,
    timePropSettings: {
      format: "24hr"
    },
    datepicker: new Date().toISOString().substr(0, 10),
    timepicker: null,
    challenges: [],
    selectedChallenges:[],
    headers: [
      {
        text: 'Status',
        value: 'enabled'
      },
      {
        text: 'Challenge name',
        value: 'name'
      },
      {
        text: 'Category',
        value: 'category'
      },
      {
        text: 'Author',
        value: 'author'
      }
    ],
  }),
    computed: {
    getSelectedChallenges: function() {
      let scheduledChalls = [];
      Object.keys(this.selected).forEach(key => this.selected[key] ? scheduledChalls.push(parseInt(key)) : false)
      return scheduledChalls
    }
  },
  methods: {
    getChallenges: function() {
      api.get("/admin/challenges", this.$store.state.token)
      .then(res => {
        if (!res.success) {
          this.error = res.message
          return false
        }
        let tmpChallenges = res.challenges
        api.get('/admin/schedule', this.$store.state.token)
        .then(res => {
          if (!res.success) {
            this.error = res.message
            /* eslint no-console: warn */
            console.log('Failed to get schedules')
            return false
          }
          if (!Object.keys(res.schedules).length) return this.challenges = tmpChallenges;
          tmpChallenges.forEach((chall, pos) => {
            if (res.schedules[chall.id]) tmpChallenges[pos].scheduled = new Date(res.schedules[chall.id]).toLocaleString()
            else tmpChallenges[pos].scheduled = null
          });
          this.challenges = tmpChallenges;
        })
      })
    },
    refresh: function() {
      this.getChallenges();
      this.refreshBtnIcon = true
      setTimeout(() => {
          this.refreshBtnIcon = false
      }, 2000);
    },
    showScheduleDialog: function() {
      this.scheduleDialogError = false
      this.scheduleDialog = true
    },
    setSchedule: function() {
      console.log(this.selected);
      /*
      *   THIS IS NOT A PLACE OF HONOR;
      *   NO HIGHLY ESTEEMED DEED IS COMMEMORATED HERE
      *   THIS MESSAGE IS A WARNING ABOUT DANGER
      *   IT INCREASES TOWARDS A FUNCTION
      *   THE CENTER OF THE DANGER IS HERE
      *   THE FUNCTION IS BEST LEFT SHUNNED AND UNINHABITED
      */
      this.isLoading = true
      let scheduleTime = new Date()
      let splitDate = this.datepicker.split('-')
      let splitTime = this.timepicker.split(':')
      console.log(splitDate);
      scheduleTime.setFullYear(splitDate[0])
      scheduleTime.setMonth(splitDate[1]-1,splitDate[2])
      scheduleTime.setHours(splitTime[0], splitTime[1], 0, 0)

      api.post('/admin/schedule', {
        id: this.getSelectedChallenges,
        time: scheduleTime
      }, this.$store.state.token)
      .then(res => {
        this.isLoading = false
        this.scheduleTime = null
        if (!res.success) return this.scheduleDialogError = res.message
        this.selected = {}
        this.datepicker = null
        this.timepicker = null
        this.scheduleDialog = false
        this.getChallenges();
      })
    },
    removeSchedule: function() {
      api.delete('/admin/schedule', {
        id: this.getSelectedChallenges
      }, this.$store.state.token)
      .then(res => {
        this.isLoading = false
        this.scheduleTime = null
        if (!res.success) return this.scheduleDialogError = res.message
        this.selected = {}
        this.datepicker = null
        this.timepicker = null
        this.scheduleDialog = false
        this.getChallenges();
      })
    }
  },
  created() {
    this.getChallenges();
  },
}
</script>

<style scoped>
.circle {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 8px;
}

.status-wrap {
  display: flex;
  align-items: center;
}

.btn-schedule {
  margin-right: 1rem;
}

</style>