<template>
  <div class="register">
    <v-card  class="medium-card">
        <v-card-title class="card-title-center">Register</v-card-title>
        <v-card-text>
            <v-form ref="registerForm">
                <v-text-field type="email" ref="email" label="Email" v-model="email" :rules="emailRule" required @focus="$refs.email.resetValidation()"></v-text-field>
                <v-text-field ref="usr" label="Username" v-model="username" :rules="usernameRule" required @focus="$refs.usr.resetValidation()"></v-text-field>
                <v-text-field ref="pwd" label="Password" v-model="password" :rules="passwordRule" required @focus="$refs.pwd.resetValidation()" type="password"></v-text-field><br>
                <v-text-field ref="con" label="Confirm password" v-model="confirmPassword" :rules="confirmPasswordRule" required @focus="$refs.con.resetValidation()" type="password" @keydown.enter="tryRegister()"></v-text-field><br>
                <v-col class="text-right">
                    <v-btn @click="tryRegister()" :loading="isLoading">Register</v-btn>
                </v-col>
            </v-form>
        </v-card-text>
    </v-card>
    <v-alert class="medium-card" type="error" v-if="error"> {{error}} </v-alert>
  </div>
</template>

<script>
import api from '@/Requests.js'
export default {
  name: 'home',
  data: () => ({
      username: undefined,
      usernameRule: [v => !!v || "Username is required", v => v != undefined && v.length >= 4 || "Username must be at least 4 characters"],
      email: undefined,
      emailRule: [v => /.+@.+/.test(v) || "Email is required"],
      password: undefined,
      passwordRule: [v => !!v || "Password is required"],
      confirmPassword: undefined,
      confirmPasswordRule: [v => !!v || "Password confirmation is required"],
      isLoading: false,
      error: false
    }),
  methods: {
      tryRegister: function () {
        if (this.password != this.confirmPassword) {
          this.error = "Passwords do not match"
          this.password = undefined
          this.confirmPassword = undefined
          return false
        }
        if (!this.$refs.registerForm.validate()) {
          return false
        }
        this.isLoading = true
        api.post("/register", {
          "email": this.email, 
          "username": this.username, 
          "password": this.password,
          "confirm": this.confirmPassword
        }).then(res => {
          this.$refs.registerForm.resetValidation()
          this.isLoading = false
          if (!res.success) {
            this.password = undefined
            this.confirmPassword = undefined
            this.error = res.message
            return false
          }
          this.email = undefined
          this.username = undefined
          this.error = false
          this.$store.state.token = res.token
          window.localStorage.setItem('token', res.token)
          this.$store.state.token_exp = res.expire
          window.localStorage.setItem('exp', res.expire)
          this.$store.state.group = res.group
          window.localStorage.setItem('group', res.group)
          this.$store.dispatch('activateStreamingEventData', api.apiURL + this.$store.state.sse_connection);
          this.$router.push('/')
        })
      },
      resetValidation: function () {
          this.$refs.loginForm.resetValidation()
      }
  }
}
</script>

<style scoped>
.card-title-center {
    justify-content: center;
}
</style>
